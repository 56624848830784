import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { HubHeader, HelloImg, DateButton, VideoGallery, RegistrationForm, SEO } from 'components';
import { LocalContext, FirebaseContext, SmootherContext } from 'context';
import { P, defaultColors } from 'styles';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { hexToRGB } from 'utils';
import { useIntersection, useEventData, useIsomorphicLayoutEffect } from 'hooks';

function Home() {
  const { theme, isMobile, registerRef, homeRef } = useContext(LocalContext);
  const smoother = useContext(SmootherContext);
  const [helloRef, observerEntry] = useIntersection({ threshold: 0 });
  const { user, firebase } = useContext(FirebaseContext);
  const currentUser = firebase?.auth?.currentUser;
  const { edges: events } = useEventData();

  const bgArrowsImgRef = useRef();

  const { backgroundArrowsImg } = useStaticQuery(graphql`
    query {
      backgroundArrowsImg: file(extension: { eq: "png" }, name: { eq: "background-arrows" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  useIsomorphicLayoutEffect(() => {
    if (smoother && bgArrowsImgRef.current) {
      smoother.effects(bgArrowsImgRef.current, {
        speed: 0.5
      });
    }
  }, [smoother, bgArrowsImgRef.current]);

  return (
    <PageWrapper
      ref={homeRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.5, duration: 1 }}
      $theme={theme}>
      <SEO pageSpecificTitle="Home" />
      <HubHeader colors={defaultColors} />
      <Main ref={registerRef} $theme={theme}>
        <BackgroundArrowsImg ref={bgArrowsImgRef}>
          <GatsbyImage image={getImage(backgroundArrowsImg)} alt="Neon Arrows" />
        </BackgroundArrowsImg>
        <About>
          <H2 colors={defaultColors}>
            <span>Winter 2022</span>
          </H2>
          <P $theme={theme}>
            Virtuosity is the one-off hybrid event brought to you by trusted corporate events &
            video specialists Get Broadcasting. Virtuosity brings together an industry experts-led
            show where we dive into the virtual realms of live streaming events, broadcast services,
            video, animation and look to the future of the virtual, digital and hybrid media.
          </P>
          <P $theme={theme}>
            Taking place this Winter 2022, this lunchtime show will bring you panel discussions,
            insights into the industry, a look at behind the scenes, as well as one-on-one talks
            with key speakers.
          </P>
          <P $theme={theme}>
            Register today and get set for a whole new world of hybrid and virtual reality.
          </P>
          {events.length === 1 && (
            <DateButton
              eventName={events[0].node.frontmatter.name}
              eventDescription={events[0].node.frontmatter.description}
              eventStartTime={events[0].node.frontmatter.startTime}
              eventEndTime={events[0].node.frontmatter.endTime}
              eventLocation={events[0].node.frontmatter.location}
              colors={events[0].node.frontmatter.colors}
            />
          )}
        </About>
        {!isMobile && (
          <RegFormGrid ref={helloRef}>
            {!currentUser && (
              <>
                <RegistrationForm colors={defaultColors} />
                <HelloImg observerEntry={observerEntry} delay={0.3} stiffness={375} />
              </>
            )}
          </RegFormGrid>
        )}
      </Main>
      <VideoGallery colors={defaultColors} />
    </PageWrapper>
  );
}

const Main = styled.section`
  color: ${({ $theme }) => $theme.textColor};
  display: grid;
  grid-column: 1/13;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 3.75rem 1.25rem;
  position: relative;

  @media only screen and (min-width: 768px) {
    padding: 17.05rem 0 9.9rem;
  }

  @media only screen and (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.25rem;
  }
`;

const BackgroundArrowsImg = styled.div`
  position: absolute;
  top: 46%;
  width: 100%;
`;

const About = styled.div`
  grid-column: 1/7;
  position: relative;

  @media only screen and (min-width: 768px) {
    grid-column: 2/6;
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const H2 = styled.h2`
  color: inherit;
  font-family: proxima-nova, sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;

  span {
    border-bottom: 0.056em solid ${({ colors }) => colors.primary};
    filter: drop-shadow(
      0px 3px 7px ${({ colors }) => hexToRGB({ color: colors.primary, alpha: 0.5 })}
    );
    padding-top: 0.111em;
  }

  @media only screen and (min-width: 1150px) {
    font-size: 2.75rem;
    margin-bottom: 1em;
  }
`;

const RegFormGrid = styled.div`
  grid-column: 8/12;
  position: relative;
  form {
    box-shadow: 6px 6px 30px 3px rgba(0, 0, 0, 0.35);
    position: relative;
    width: 100%;
    z-index: 3;
  }
`;

const PageWrapper = styled(motion.div)`
  background: #161616;
  box-sizing: border-box;
  display: grid;
  filter: ${({ $theme }) => ($theme.className === 'grayscale' ? 'grayscale(100%)' : 'none')};
  grid-column: 1/13;
  grid-column-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  overflow: hidden;

  @media only screen and (min-width: 1150px) {
    grid-column-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default Home;
